import React from 'react'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/pipeable'
import { useSpring, animated } from 'react-spring'
import { motion } from 'framer-motion'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Layout from '../components/layout'
import { fuzzysearch } from '../util/fuzzy-search'
import { useProducts } from '../hooks/useProducts'
import { EndPoint } from '../components/nav/header-nav'
import { Product } from './fit-kidz-4-fun-registration'
import { sequenceOptionsStruct } from '../util/applicatives'
import { newIO } from '../util/io'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
)

export const SummerCamp = () => {
  const classes = useStyles()

  const springOpacity = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  })

  const [searchTerm, setSearchTerm] = React.useState<O.Option<string>>(O.none)
  const [selectedPricePlan, setSelectedPricePlan] = React.useState<
    O.Option<{ planId: string; priceId: string; billingFrequency: string }>
  >(O.none)

  const { availableSummerCamps } = useProducts()

  const summerCampCollection = pipe(
    availableSummerCamps,
    O.fold(
      () => null,
      schools =>
        O.isSome(searchTerm)
          ? schools.filter(s =>
              fuzzysearch(
                searchTerm.value.toLocaleLowerCase(),
                s.productName.toLocaleLowerCase()
              )
            )
          : schools
    )
  )

  const handleRegistration = (summerCampProduct: Product) => {
    const hasSelectedCorrespondingPricePlan = pipe(
      O.fromNullable(summerCampProduct.alternativePrices.data),
      O.chain(O.fromPredicate(A.isNonEmpty)),
      O.fold(
        () => [summerCampProduct.billingMeta._id],
        prices => [summerCampProduct.billingMeta._id, ...prices.map(p => p._id)]
      ),
      A.findFirst(
        p =>
          p ===
          pipe(
            selectedPricePlan,
            O.map(p => p.priceId),
            O.getOrElse(() => '')
          )
      )
    )
    pipe(
      sequenceOptionsStruct({
        selectedPlan: selectedPricePlan,
        hasSelectedCorrespondingPricePlan,
      }),
      O.fold(
        () =>
          newIO(() =>
            toast.error(
              "Make sure you've selected a corresponding plan for the summer camp you're trying to register for.",
              { position: toast.POSITION.TOP_RIGHT }
            )
          ),
        ({ selectedPlan }) =>
          newIO(() => {
            navigate(
              `${EndPoint.FIT_KIDZ_4_FUN_REGISTRATION}?plan=${selectedPlan.planId}&price=${selectedPlan.priceId}&type=summer-camp&billingFrequency=${selectedPlan.billingFrequency}`
            )
          })
      )
    )()
  }

  return (
    <Layout seoTitle="Summer Camp">
      <animated.div
        style={springOpacity}
        className="bg-gray-50 overflow-hidden"
      >
        <div className="relative max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-gray-100 absolute top-0 bottom-0 left-3/4 w-screen"></div>
          <div className="mx-auto text-base lg:max-w-none">
            <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-cool-gray-700 sm:text-4xl sm:leading-10">
              Summer camp returns!
            </h1>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2">
              <svg
                className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div className="relative text-base mx-auto lg:max-w-none">
                <figure>
                  <div className="relative pb-7/12 lg:pb-0">
                    <img
                      src="https://lh3.googleusercontent.com/p/AF1QipPjRm4KgYSpjmhUvHsLpzDzuDiKX0woSLexdnIz=h305-no"
                      alt="summer camp at fit kidz 4 fun"
                      width="1184"
                      height="1376"
                      className="rounded-lg shadow-lg object-cover object-center absolute inset-0 w-full h-full lg:static lg:h-auto"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div>
              <div className="text-base mx-auto lg:max-w-none">
                <p className="text-lg leading-7 text-gray-500 mb-5">
                  Cortiz Fitness presents its amazing FIT KIDZ 4 FUN 2021 Summer
                  Camp! Our camp is a kid&apos;s wildest dream.
                </p>
              </div>
              <div className="mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p className="mt-4 text-gray-500">
                  Kids in our camp will participate in weekly themed activities,
                  various sports of the week, elective classes, arts and crafts,
                  obstacle courses and much more! A good portion of our
                  activities are also done within their separate age groups such
                  as arts and crafts, and team-building games.
                </p>

                <h3 className="mt-4 text-accent-400 ">
                  THIS IS AN OUTDOOR AND INDOOR CAMP!
                </h3>

                <p className="mt-4 text-gray-500">
                  Each week there will be a featured sport and theme of the
                  week. All week kids will learn and play that team sport,
                  participate in fun themed games and activities.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="relative max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <span className="text-2xl leading-6 font-semibold tracking-wide text-cool-gray-600">
              All ocps schools are Monday through Thursday
            </span>
            <span className="text-2xl leading-6 font-semibold tracking-wide text-cool-gray-600">
              with some field trips scheduled for fridays.
            </span>
          </div>
        </div>
        <div className="relative max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="col-span-1 md:col-span-1 bg-cool-gray-100 overflow-hidden rounded-lg shadow-md">
              <p className="px-4 py-5 sm:p-6 text-cool-gray-800">
                Wednesdays are water days in which KIDS will get to participate
                in water activities such as water balloon games, slip and
                slides, water buckets and much Much more.
              </p>
            </div>
            <div className="col-span-1 md:col-span-2 bg-white overflow-hidden rounded-lg shadow-md">
              <p className="px-4 py-5 sm:p-6 text-cool-gray-600">
                Participants must be 5 years old or older starting May 31st,
                2021 to attend.{' '}
                <strong>
                  An EXCEPTION will be made to 4 year old kids who have
                  completed VPK.
                </strong>{' '}
                All kids are allowed to join our camp regardless on what school
                they belong to.{' '}
                <strong>Maximum age to attend camp is 12 years old.</strong>
              </p>
            </div>
          </div>
        </div>
      </animated.div>
      <div className="relative py-8 px-4 sm:px-6 lg:px-8 bg-cool-gray-600">
        <div className="mx-auto max-w-7xl">
          <p className="-mt-4 mb-2 text-2xl text-white font-bold">
            Weekly themes
          </p>
          <div className={`${classes.root} bg-cool-gray-600`}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  <strong>June 1st - 4th</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col">
                  <span className="text-xl">Olympics</span>
                  <Typography>
                    Kids will be group by different countries. They will learn
                    about them and compete all week long for points in fun
                    events based off of the summer and winter Olympic games.
                    Each camper will win either a gold, silver, or bronze medal
                    for their country at our closing ceremony on Thursday
                    afternoon. Hockey, basketball, weight-lifting (pool noodles)
                    and track and field events are some of the Olympic game
                    highlights!
                  </Typography>
                  <Typography className="text-yellow-500">
                    Sport of the Week: Olympic sports
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  <strong>June 8th - 12th</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col">
                  <p className="text-xl">IT’S A MYSTERY</p>
                  <p className="text-yellow-400">(Field trip TBD)</p>
                  <Typography>
                    Calling all detectives! Collect clues, solve crimes, find
                    long lost artifacts, uncover buried treasure, unlock escape
                    rooms and compete in scavenger hunts in this fun filled
                    week! This theme was a kid&apos;s choice and popular
                    favorite the past 2 years.
                  </Typography>
                  <Typography className="text-yellow-500">
                    Sport of the Week: Volleyball
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography className={classes.heading}>
                  <strong>June 15th – 18th</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col">
                  <span className="text-xl">BATTLE OF THE GAMES</span>
                  <Typography>
                    Our newly created Kids ACTIVE video game theme. Here we use
                    the characters and dance moves to make a creative, one of a
                    kind fitness challenge. kids will do fun challenges, games
                    after the ever so popular video games like Fortnite, Apex
                    Legends, Super Mario, Pokeman and much more.
                  </Typography>
                  <Typography className="text-yellow-500">
                    Sport of the Week: Baseball
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Typography className={classes.heading}>
                  <strong>June 22nd – 26th</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col">
                  <span className="text-xl">HARRY POTTER (NEW THEME)</span>
                  <p className="text-yellow-400">
                    Field trip the 26TH WONDER WORKS $60
                  </p>
                  <Typography>
                    Calling all wizards! This is one of our newest themes based
                    off of the ever popular Harry Potter! Kids will be split
                    into the teams the sorting hat decides. Amazing arts an
                    crafts such as potions and making their own spells. Kids
                    learn about the Wizarding world and become an active wizard.
                  </Typography>
                  <Typography className="text-yellow-500">
                    Sport of the Week: Soccer
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5a-content"
                id="panel5a-header"
              >
                <Typography className={classes.heading}>
                  <strong>July 6th – 9th</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col">
                  <span className="text-xl">OUR KIDZ HAVE TALENT</span>
                  <Typography>
                    It’s time to show off your child’s talent! Everyone has a
                    hidden talent and we will discover it. Your child chooses,
                    dance, drama, singing or misc to practice their talent.
                  </Typography>
                  <Typography>
                    Parents are invited to witness an amazing performance by the
                    kids and coaches.
                  </Typography>
                  <Typography className="text-yellow-500">
                    Sport of the Week: Megaball (cortizfitness most popular
                    featured game which combines soccer, basketball and football
                    in 1)
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6a-content"
                id="panel6a-header"
              >
                <Typography className={classes.heading}>
                  <strong>July 13th – 17th</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col">
                  <span className="text-xl">SUPERHEROES VS VILLAINS</span>
                  <p className="text-yellow-400">(Field trip TBD)</p>
                  <Typography>
                    Kids become their own Superhero by creating, Designing and
                    Acting out their superhero powers all week. It’s superheros
                    vs villains in an all week battle of fun activities with
                    this theme kids learn what a real super hero is and should
                    be. Kids will love this week! Campers dress up as their
                    heroes!
                  </Typography>
                  <Typography className="text-yellow-500">
                    Sport of the Week: Kickball
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7a-content"
                id="panel7a-header"
              >
                <Typography className={classes.heading}>
                  <strong>July 20th – 23rd</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col">
                  <span className="text-xl">
                    MASTER CHEF JUNIOR (NEW THEME)
                  </span>
                  <Typography>
                    Ready to take on Gordan Ramsey and learn what it takes to be
                    a Master chef? Cortiz fitness gets creative and brings the
                    theme of master chef junior to life for this week. Kids
                    learn creative healthy meals to make while learning to live
                    active lifestyle. enjoy camp food tastings, race the clock
                    and tackle the judges. welcome to Master Chef junior summer
                    camp style
                  </Typography>
                  <Typography className="text-yellow-500">
                    Sport of the Week: Team Sport Challenges
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8a-content"
                id="panel8a-header"
              >
                <Typography className={classes.heading}>
                  <strong>July 27th – 31st</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col">
                  <span className="text-xl">SURVIVOR</span>
                  <p className="text-yellow-400">(Field trip TBD)</p>
                  <Typography>
                    Join us for Kids Rock Survivor week. Kids with their tribe
                    will compete in obstacle courses, scavenger hunts, other
                    reward challenges similar to those seen on the hit TV show
                    “Survivor”. Don’t worry, campers will not be voted off the
                    island.
                  </Typography>
                  <Typography className="text-yellow-500">
                    Sport of the Week: Kids choose their favorite activities and
                    sports Kids vote and participate on their favorite
                    activities among the whole summer and have a contest
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <animated.div style={springOpacity} className="bg-gray-50">
        <div className="relative max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div className="text-center my-6">
            <div className="flex flex-col">
              <p className="text-4xl font-semibold tracking-wide text-yellow-400">
                Camp runs starting June 1st - August 13th
              </p>
              <p className="text-4xl font-semibold tracking-wide text-yellow-400 text-opacity-75">
                Ocps camps will end August 6th
              </p>
            </div>
          </div>
          <p className="text-center text-cool-gray-600">
            <span className="text-2xl leading-6 font-semibold tracking-wide uppercase">
              Current locations{' '}
            </span>
            <span className="text-medium">(subject to change)</span>
          </p>
          <div className="mt-2 mb-6 flex justify-center">
            <div className="w-96">
              <label htmlFor="search-school" className="sr-only">
                Search
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <svg
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    >
                      <path d="M15.853 16.56A9.458 9.458 0 019.5 19C4.257 19 0 14.743 0 9.5S4.257 0 9.5 0 19 4.257 19 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zM9.5 1C14.191 1 18 4.809 18 9.5S14.191 18 9.5 18 1 14.191 1 9.5 4.809 1 9.5 1z" />
                    </svg>
                  </svg>
                </div>
                <input
                  id="search-school"
                  className="form-input block w-full pl-10 sm:text-sm sm:leading-5"
                  placeholder="Search for location.."
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchTerm(
                      e.target.value !== ''
                        ? O.some(e.target.value.trim())
                        : O.none
                    )
                  }
                />
              </div>
            </div>
          </div>
          {pipe(
            O.fromNullable(summerCampCollection),
            O.fold(
              () => null,
              schools => (
                <ul className="grid grid-cols-1 gap-8 sm:grid-cols-2 mb-6 px-4">
                  {schools.length > 0 ? (
                    schools.map(s => (
                      <li
                        key={s._id}
                        className="col-span-1 flex flex-col bg-white rounded-lg shadow-md hover:shadow-lg"
                      >
                        <div className="flex-1 flex flex-col p-8">
                          <img
                            className="w-32 h-32 flex-shrink-0 mx-auto bg-black rounded-full object-cover"
                            src={
                              s?.imageUrl ??
                              'https://res.cloudinary.com/leportail-app/image/upload/v1601761785/Screen_Shot_2020-10-03_at_5.49.22_PM_hjypvz.png'
                            }
                            alt=""
                          />
                          <h3 className="mt-6 text-cool-gray-500 text-md leading-5 font-medium">
                            {s.productName}
                          </h3>
                          {pipe(
                            O.fromNullable(s?.weekDaysAvailable),
                            O.fold(
                              () => null,
                              days => (
                                <dl className="mt-1 flex-grow flex flex-col justify-between">
                                  <dt className="sr-only">
                                    Available days of the week
                                  </dt>
                                  <ul className="bg-cool-gray-100 p-2 rounded-md">
                                    {days.map((d, idx) => (
                                      <li
                                        key={d + idx}
                                        className="text-cool-gray-500 text-sm"
                                      >
                                        {d}
                                      </li>
                                    ))}
                                  </ul>
                                </dl>
                              )
                            )
                          )}
                          {pipe(
                            O.fromNullable(s.alternativePrices.data),
                            O.chain(O.fromPredicate(A.isNonEmpty)),
                            O.fold(
                              () => null,
                              prices => (
                                <div className="mt-1 flex flex-col">
                                  <div>
                                    <input
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 cursor-pointer border-gray-300"
                                      style={{ appearance: 'revert' }}
                                      type="radio"
                                      id={s.billingMeta._id}
                                      name="price-plan"
                                      value={s.billingMeta._id}
                                      defaultChecked={
                                        pipe(
                                          selectedPricePlan,
                                          O.map(p => p.priceId),
                                          O.getOrElse(() => '')
                                        ) === s.billingMeta._id
                                      }
                                      onClick={e =>
                                        setSelectedPricePlan(
                                          O.some({
                                            planId: s._id,
                                            priceId: s.billingMeta._id,
                                            billingFrequency:
                                              s.billingMeta.billingFrequency,
                                          })
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={s.billingMeta._id}
                                      className="ml-2 text-sm text-cool-gray-600"
                                    >
                                      {
                                        s.billingMeta
                                          .clientFacingPriceDescription
                                      }
                                    </label>
                                    <br />
                                  </div>
                                  {pipe(
                                    prices,
                                    A.map(price => (
                                      <div
                                        key={price._id}
                                        className="inline-flex items-center"
                                      >
                                        <input
                                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 cursor-pointer border-gray-300"
                                          style={{ appearance: 'revert' }}
                                          type="radio"
                                          id={price._id}
                                          name="price-plan"
                                          value={price._id}
                                          onClick={e =>
                                            setSelectedPricePlan(
                                              O.some({
                                                planId: s._id,
                                                priceId: price._id,
                                                billingFrequency:
                                                  price.billingFrequency,
                                              })
                                            )
                                          }
                                          defaultChecked={
                                            pipe(
                                              selectedPricePlan,
                                              O.map(p => p.priceId),
                                              O.getOrElse(() => '')
                                            ) === price._id
                                          }
                                        />
                                        <label
                                          htmlFor={price._id}
                                          className="ml-2 text-sm text-cool-gray-600"
                                        >
                                          {price.clientFacingPriceDescription}
                                        </label>
                                        <br />
                                      </div>
                                    ))
                                  )}
                                </div>
                              )
                            )
                          )}
                        </div>
                        <div className="border-t rounded-b-md bg-cool-gray-200">
                          <div className="-mt-px flex">
                            <div className="w-0 flex-1 flex border-r rounded-b-md bg-cool-gray-200">
                              <div
                                onClick={() => handleRegistration(s)}
                                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-cool-gray-600 leading-5 font-medium border border-transparent rounded-bl-lg focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 cursor-pointer"
                              >
                                <svg
                                  className="w-6 h-6"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                >
                                  <path d="M9.963 8.261c-.566-.585-.536-1.503.047-2.07l5.948-5.768c.291-.281.664-.423 1.035-.423.376 0 .75.146 1.035.44l-8.065 7.821zm-9.778 14.696c-.123.118-.185.277-.185.436 0 .333.271.607.607.607.152 0 .305-.057.423-.171l.999-.972-.845-.872-.999.972zm8.44-11.234l-3.419 3.314c-1.837 1.781-2.774 3.507-3.64 5.916l1.509 1.559c2.434-.79 4.187-1.673 6.024-3.455l3.418-3.315-3.892-4.019zm9.97-10.212l-8.806 8.54 4.436 4.579 8.806-8.538c.645-.626.969-1.458.969-2.291 0-2.784-3.373-4.261-5.405-2.29z" />
                                </svg>
                                <span className="ml-3">Register Now</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="col-span-4">
                      <div className="flex justify-center text-cool-gray-400 mb-80">
                        sorry, summer camp not found
                      </div>
                    </div>
                  )}
                </ul>
              )
            )
          )}
        </div>
      </animated.div>
    </Layout>
  )
}

export default SummerCamp
